.arrow-cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    position: relative;
    animation: fadeIn 2s ease-in-out;
}

.cta-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
    animation: bounceText 2s infinite;
}

.arrow-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: -1px;
}

.arrow {
    width: 20px;
    height: 20px;
    border-left: 2px solid transparent; /* Ensure only bottom and right borders are visible */
    border-bottom: 2px solid #ffffff; /* Make bottom border visible for downward arrow */
    border-right: 2px solid #ffffff; /* Make right border visible for downward arrow */
    transform: rotate(45deg); /* Point the arrow downward */
    animation: bounceArrow 2s infinite;
}

.arrow:nth-child(2) {
    animation-delay: 0.2s;
}

.arrow:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounceText {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

@keyframes bounceArrow {
    0%, 100% {
        transform: rotate(45deg) translateY(0);
    }
    50% {
        transform: rotate(45deg) translateY(8px);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

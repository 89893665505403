.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
  }
@keyframes slideInFromTopFadeIn {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
}

.navbar {
    width: 100%;
    position: relative;
    font-size: 1.4vw;
}

.navbar-home {
    opacity: 0;
    animation: slideInFromTopFadeIn 0.6s forwards;
}
.navbar-link {
    background-color: transparent;
    color: #e9e2e2;
    transition: color 0.3s ease;
    position: relative;
    margin-left: 1vw;
    margin-right: 1vw;
    background-color: transparent;
    border-radius: 10px;
    opacity: 0;
    animation: slideInFromTopFadeIn 0.6s forwards;
}

.navbar-link:hover {
    color: white;
    background-color: rgba(200, 215, 222, 0.1);
    transition: background-color 0.5s ease;
}

.navbar-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #ffffff;
    transition: width 0.5s ease;
    margin-top: 10px;
}

.navbar-link:hover::after {
    margin-top: 10px;
    width: 100%;
    left: 0;
    background: #771b1b;
}
.navbar-link.active {
    font-weight: bold;
}
.social-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-left: 40vw;
}
.social-icon {
    color: #d7d5d5a0; 
    margin-left: 15px;
    transition: color 0.3s ease;
    text-decoration: none;
}

.social-icon:hover {
    color: #ffffff; /* Change color on hover */
}


.offcanvas.offcanvas-end {
    width: 250px; /* Adjust width */
}

.offcanvas-body {
    display: flex;
    flex-direction: column;
    align-items: start;
}

  /* For phones and small devices */
@media (max-width: 784px) {
    .navbar {
        font-size: 2.5vw;
    }
    .navbar-title {
        font-size: 7vw;
        margin-bottom: 0;
        margin-top: 8px;
        color: white;
        font-weight: 800;
        
    }
    .navbar-link {
        margin-left: 0;
        margin-right: 0;
        font-weight: 500;
        font-size: 5vw;
        margin-bottom: 3vh;
    }
        /* Change mobile navbar (Offcanvas) background color */
    .offcanvas {
        background-color: var(--mco-red) !important;
    }

    /* Ensure text is visible */
    .offcanvas .navbar-link {
        color: white !important; /* Ensure text contrast */
    }

    .offcanvas .navbar-link:hover {
        background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
    }

    /* Adjust close button for better visibility */
    .offcanvas-header .btn-close {
        filter: invert(1); /* Make close button white */
    }
    .social-icon-container {
        margin-top: 40px;
        margin-left: 30px;
        width: 250%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        flex-wrap: wrap; 
        gap: 20px;
    }
    .social-icon {
        margin-left: -30px;
        color: white;
        font-size: 2rem;
    }
}
.App {
  text-align: center;
  overflow-x: hidden; /* Prevent horizontal scroll issues */
}

/* Content Container */
.content-container {
  position: absolute;
  z-index: 1; /* Ensure content is above the background */
  background: transparent; /* Allow the background to show through */
  width: 100%;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.background-layer {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease-in-out, transform 0s ease-in-out;
  opacity: 1;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0.3;
  }
  to {
      opacity: 1;
  }
}

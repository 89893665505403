.events-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;
    padding-left: 30px;
    padding-right:30px;
  }
/* Desktop stylings */
.events-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 60vh;
    margin-left: 0px;
    margin: 50px;
    perspective: 1000px; /* Adds depth for stacking effect */
    opacity: 0.9;
}

/* Card styling */
.card {
    width: 250px;
    height: 40vh;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 3s ease, z-index 3s ease;
    cursor: pointer;
    position: absolute;
    text-align: left;
}
.card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.card:active {
    transform: scale(0.80);
    box-shadow: 0 5px 15px rgba(203, 155, 155, 0.8);
    transition: transform 0.2s ease;
}
.card-title {
    position: absolute;
    bottom: 30px;
    left: 20px;
    font-size: 0.7vw;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 1);
}
.view-details-button {
    margin-top: 35.7vh;
    margin-left: 1.3vw;
    width: 4vw;
    height: 2vh;
    background: rgba(129, 113, 113, 0.8);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.5vw;
    transition: background 0.7s ease;
}
.view-details-button:hover {
    background: rgba(255, 255, 255, 1);
}

.view-details-button:active {
    transform: scale(0.95);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
}

/* Desktop Modal Styling */

/* Modal Overlay (Fades in) */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    animation: slideInRight 0.4s ease-out;
}

/* Modal Content (Make it a flexible column) */
.modal-content {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: space-between;
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: justify;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    width: 30vw;
    max-width: 80%;
    height: 70vh;
    left: 10%;
    top: 6.5%;
    opacity: 0;
    transform: scale(0.8);
    animation: slideInRight 0.4s ease-out forwards;
}
.modal-body {
    flex-grow: 1; /* This pushes the close button to the bottom */
    overflow-y: auto; /* Scrollable if too much content */
    padding-bottom: 20px; /* Give space above the button */
}
.modal-content h2 {
    font-size: 1.5vw;
    text-align: center;
    margin-bottom: 3vh;
}
.modal-content p {
    font-size: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
/* Close Button */
.close-button {
    padding: 10px 15px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.8s ease;
    width: 28%;
    align-self: center; /* Ensure it's centered */
    margin-bottom: 10px; /* Space from bottom */
}

.close-button:active {
    transform: scale(0.95);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
}


/* Mobile Container styling */
  .events-title {
    color: #ffffff;
    font-size: 7.5vw;
    font-weight: 700;
    margin-right: 8vw;
    margin-bottom: 2vh;
    text-align: center;
  }
  .event-card img {
    width: 100%; /* Ensures the image fills the container width */
    height: auto; 
    max-height: 40vh;
    border-radius: 8px;
    object-fit: cover; /* Ensures proper cropping */
}

  .events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Dynamic columns */
    gap: 16px; /* Space between cards */
  }
  
  .event-card {
    display: flex;
    flex-direction: column;
    background-color: var(--mco-light-light-red) !important;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    opacity: 0.9;
    width: 100%;
    height: 70vh;
  }
  .event-title {
    font-size: 4.5vw; /* Adjust font size for mobile */
    font-weight: 700;
    margin-bottom: 0.5vh; /* Space between title and description */
    margin-top: 0.5vh;
  }
  .event-description {
    max-height: 35vh;
    overflow-y: auto;
    padding: 5px;
    font-size: 0.9rem;
    line-height: 1.4; 
    text-align: justify;
    scrollbar-width: thin;
}

.event-description::-webkit-scrollbar {
    width: 5px; /* Thin scrollbar */
}

.event-description::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3); /* Subtle scrollbar color */
    border-radius: 10px;
}

/* Mobile Swipeable Slider */
.swiper {
    width: 100%;
    height: auto;
    position: relative;
    overflow: visible;
  }
  
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
  }

.swiper .swiper-button-next {
  z-index: 10;
  left: 93%;
}
.swiper .swiper-button-prev {
  z-index: 10;
  left: 0%;
}

.page-wrapper {
    width: 100%;
    box-sizing: border-box;
}

/* Hero Section */
.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
    color: #fff;
    font-family: 'EB Garamond', serif;
}

.hero h1 {
    font-size: 3.5vw;
    margin-top: 10vh;
    margin-bottom: 5vh;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
    font-weight: 600;
}

.hero p {
    font-size: 1.5vw;
    margin-bottom: 30px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
}


/* Utility Classes for Shared Consistency */
.text-center {
    text-align: center;
}

.margin-auto {
    margin: 0 auto;
}

.home-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn {
    display: inline-block;
    width: 11vw;
    min-height: 6vh;
    margin-left: 2vw;
    margin-right: 2vw;
    font-size: 1.3vw;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    padding: 12px 25px;
    font-weight:500;
    background-color: var(--btn-bg-color, '#892c2d');
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.5s, transform 0.2s;
}
.btn:hover {
    background-color: #ffffff;
    box-shadow: #bab2b2 0px 0px 5px;
    color: #000000;
    font-weight: bold;
    transform: scale(1.05);
}


.homepage-content {
    background-color: #f9f9f9;
    text-align: center;
    border-top: 1px solid #ddd;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: left;
    align-items: left;
    padding-top: 20px;
    padding-left: 20px;
    background: linear-gradient(135deg, #f5f5f5, #e0e0e0); /* Subtle gradient background */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.video-wrapper {
    position: relative;
    height: 55vh;
    width: 55vw;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Video shadow */
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width for responsiveness */
    height: 100%; /* Full height based on parent padding */
    border: none;
    border-radius: 10px; /* Match parent border radius */
}
.content-video-description{
    margin-top: 9vh;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 45vw;
}
.content-video-description-title{
    font-family: 'EB Garamond', serif;
    font-size: 2.4vw;
    color: #5e0404;
    margin-bottom: 3vh;
}
.content-video-description-subtitle{
    font-family: 'EB Garamond', serif;
    font-size: 1.7vw;
    color: #5e0404;
    margin-bottom: 2.5vh;
}
.content-video-description-content{
    margin-top: 4vh;
    margin-bottom: 4vh;
    font-family: 'EB Garamond', serif;
    font-size: 1vw;
    color: #5e0404;
    text-align: justify;
    line-height: 1.8;
}

/* Introduction Section */
.introduction {
    font-family: 'EB Garamond', serif;
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
    display: flex;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}
.introduction h2 {
    font-size: 2.4vw;
    color: #5e0404;
    margin-bottom: 20px;
}
.introduction p {
    font-size: 1vw;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
}
.introduction-text{
    margin-top: 10vh;
    margin-bottom: 10vh;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 35vw;
    color: #5e0404;
    font-size: 1vw;
}
.introduction-photos {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-left: 5vw;
    margin-right: 5vw;
}

/* Slider */
.photo-slider {
    display: flex;
    justify-content: center;
    transition: transform 0.8s ease-in-out; /* Smooth transitions */

}

/* Slider Images */
.slider-image {
    position: absolute; /* Overlay all images */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0; /* Hidden by default */
    transition: opacity 1s ease-in-out; /* Smooth fade-in/out */
    z-index: 0;
}

/* Active Image */
.slider-image.active {
    opacity: 1; /* Fully visible */
    z-index: 1;
}


/* Navigation Buttons */
.prev-btn, .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    cursor: pointer;
    z-index: 10;
    font-size: 1.2rem;
    transition: background-color 0.3s;
}

.prev-btn:hover, .next-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

@media (max-width: 768px) {
    .hero {
        height: 85vh;
    }
    .hero h1 {
        font-size: 4.5vw;
    }

    .hero p {
        font-size: 3.5vw;
    }

    .home-button-container {
        flex-direction: column; 
    }

    .btn {
        width: 28vw; 
        min-height: 5vh;
        margin: 10px 0;
        font-size: 3.5vw;
    }

    .homepage-content {
        flex-direction: column;
        padding: 10px;
        text-align: center;
        height: auto;
    }

    .video-wrapper {
        width: 90%;
        height: 45vh;
        margin: 0 auto;
    }

    .content-video-description {
        width: 90%;
        margin: 20px auto;
    }

    .content-video-description-title {
        font-size: 4vw;
    }

    .content-video-description-subtitle {
        font-size: 3vw;
    }

    .content-video-description-content {
        font-size: 2.5vw;
    }
    .prev-btn, .next-btn {
        font-size: 0.8rem;
        padding: 6px 10px;
    }
    .introduction {
        height: 55vh;
    }

    .introduction-text {
        font-size: 4vw;
        width: 100%;
    }
    .introduction h2 {
        font-size: 5vw;
        color: var(--title-color, '#892c2d');
        margin-bottom: 20px;
    }
    .introduction p {
        font-size: 2.2vw;
        line-height: 1.8;
        margin-bottom: 20px;
        text-align: justify;
    }

    .introduction-photos {
        width: 100%;
        height: 45vh;
        margin: 0 auto;
    }

    .prev-btn, .next-btn {
        font-size: 0.9rem;
        padding: 8px 12px;
    }
}

/* For screens smaller than 480px (e.g., smartphones) */
@media (max-width: 480px) {
    .hero h1 {
        font-size: 7.5vw;
    }

    .hero p {
        font-size: 5vw;
    }

    .btn {
        width: 40vw;
        font-size: 4vw;
        padding: 10px 20px;
    }

    .video-wrapper {
        height: 40vh;
    }

    .content-video-description-title {
        font-size: 5vw;
    }

    .content-video-description-subtitle {
        font-size: 4vw;
    }

    .content-video-description-content {
        font-size: 3.5vw;
    }

    .introduction-photos {
        height: 40vh;
    }

    .prev-btn, .next-btn {
        font-size: 0.8rem;
        padding: 6px 10px;
    }
    .introduction {
        padding: 20px;
        text-align: center;
        flex-direction: column; 
        align-items: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        height: 70vh;
    }

    .introduction-text {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 5vw;
        margin-right: 5vw;
        width: 87vw;
    }
    .introduction p{
        font-size: 3.5vw;
    }

    .introduction-photos {
        margin: 20px auto;
        width: 100%;
        height: 40vh;
    }

    .prev-btn, .next-btn {
        font-size: 0.9rem;
        padding: 8px 12px;
    }
}

